<template>
  <b-modal
    id="itinerary-day-autocomplete-modal"
    title="View Itinerary Day Autocomplete"
    size="xl"
    v-model="modalOpen"
    @close="closeModal"
  >
    <div v-if="generatedDay" class="service_modal">
      <div style="display: flex">
        <div style="width: 100%">
          <strong>CURRENT TEXTS FOR DAY {{ day.start_day }}</strong>
          <br />
          <br />
          <div style="height: 50px">
            <span v-html="day.title.value" />
          </div>
          <span v-html="day.description.value" />
        </div>
        <div style="width: 100%">
          <strong>GENERATED TEXTS FOR DAY {{ generatedDay.start_day }}</strong>
          <br />
          <br />
          <div style="height: 50px">
            <BaseInput
              v-model="generatedDay.title.value"
              placeholder="Add a title."
              size="large"
              class="w-100"
              :required="true"
            />
          </div>
          <VueEditor
            :value="generatedDay.description.value"
            @input="
              (value) =>
                (generatedDay.description.value =
                  helpers.preventHTMLEscaping(value))
            "
          />
        </div>
      </div>
      <BaseButton
        :loading="saving"
        size="medium"
        action="tercery"
        style="float: right; margin-top: 20px"
        @click="saveDay(generatedDay)"
      >
        <i slot="icon" class="mr-4 ml-1">
          <IconCheck class="icon is-small" />
        </i>
        <p slot="text">Save</p>
      </BaseButton>
    </div>
  </b-modal>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
import BaseInput from "@/components/base-ui/BaseInput.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import BaseButton from "@/components/base-ui/BaseButton.vue";
import helpers from "@/helpers";

export default {
  name: "ItineraryDayAutocompleteModal",
  components: {
    BaseButton,
    IconCheck,
    BaseInput,
    VueEditor,
  },
  props: {
    open: {
      type: Boolean,
      require: false,
    },
    saving: {
      type: Boolean,
      require: false,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    day: {
      type: Object,
      default: () => {},
    },
    generatedDay: {
      type: Object,
      default: () => {},
    },
    saveDay: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      itinerary: "itinerary/getItinerary",
    }),
  },
  watch: {
    open(newValue) {
      this.modalOpen = newValue;
    },
  },
  data() {
    return {
      modalOpen: false,
      helpers: helpers,
    };
  },
};
</script>

<style scoped>
.service_modal {
  background-color: #fff;
  width: 100%;
  height: auto;
  border-radius: 1rem;
  padding: 2rem 2rem 3rem;
}
</style>
